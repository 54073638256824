<template>
  <div ref="document" class="report-view task-time-sheet-report">
    <div v-if="exportLoader" class="report-loader-screen">
      <div class="d-flex align-items-center justify-content-center h-100 overdlow-sheet">
        <img src="../../../public/loader.gif" alt="">
      </div>
    </div>
    <div ref="timesheetScrollRef" class="viewEdit ttrInfo">
      <b-card class="d-flex">
        <div class="calendar justify-content-between mt-0">
          <p class="task-timesheet">
            Update Task Timesheet Report
          </p>
          <div id="date-select" class="d-flex justify-content-center align-items-center mr-0 calender">
            <button class="back-button btn btn-secondary mr-2" @click="back()">
              <feather-icon icon="ArrowLeftIcon" width="18" height="18" />
              <span>Back</span>
            </button>
          </div>
        </div>
      </b-card>

      <!-- start Filters & export -->
      <div class="card-filter-section mt-0">
        <b-row>
          <b-col cols="8" lg="9" class="d-block d-lg-flex align-items-center justify-content-start flex-wrap">
            <p class="total-hr-timespent-edit">
              Total Hours Spent : <span>{{ totalDailyTime }}</span>
            </p>
          </b-col>
          <b-col cols="4" lg="3" class="d-flex justify-content-end">
            <b-dropdown id="dropdown-right" right text="Export"
              class="custom-drop-timesheet-report filterRedesign side-bar-clear">
              <template #button-content>
                <div class="d-flex align-items-center">
                  <span class="mr-25">Export</span>
                  <feather-icon icon="ChevronDownIcon" width="18" height="18" />
                </div>
              </template>
              <b-dropdown-item @click="confirmationFunction('PDF')">
                PDF
              </b-dropdown-item>
              <b-dropdown-item @click="confirmationFunction('CSV')">
                XLSX
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
      <!-- end Filters & export -->
      <div class="report-task-section mb-5">
        <list-loader v-if="loader" :fields="fields" />
        <div v-else-if="reports.length" id="element-to-convert">
          <div class="emp-list time-sheet-list tableDesigns">
            <validation-observer>
              <b-table :items="reportsList" :fields="filteredFields" show-empty reponsive
                class="emp-list-data emp-list-report table-responsive time-sheet-report">
                <template #cell(name)="data">
                  <div v-if="showTaskId != data.item.user_task_id || !editable"
                    class="d-flex align-items-center new-time-report">
                    <b-avatar size="30" :src="data.item.picture" :text="data.item.first_name | avatarText"
                      style="background-color: #f97753" />

                    <div class="emp-detail">
                      <p class="emp-name">
                        {{ data.item.full_name ? data.item.full_name : "" }}
                      </p>
                    </div>
                  </div>
                  <div v-else>

                    <b-form-group>
                      <validation-provider #default="{ errors }" name="Project User" rules="">
                        <v-select id="Project User" v-model="user_id" :options="filterUserOptions"
                          :reduce="(user) => user.value" class="select-size-lg" name="Project User" :clearable="false"
                          placeholder=" select Project User" @input="updateUserInfo(data.item)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </template>
                <template #cell(project)="data">
                  <div class="d-flex align-items-center new-time-report">
                    <b-avatar size="30" :text="data.item.project_name
                      ? data.item.project_name.charAt(0)
                      : 'Z'
                      " :style="[
                        data.item.color
                          ? { background: `#${data.item.color}` }
                          : '',
                      ]" />
                    <div class="emp-detail">
                      <p v-b-tooltip.hover.top="data.item.project_name" class="emp-name" :style="[
                        data.item.color
                          ? { color: `#${data.item.color}` }
                          : '',
                      ]">
                        {{ data.item.project_name }}
                      </p>
                    </div>
                  </div>
                </template>
                <template #cell(date)="data">
                  <b-form-group v-if="showTaskId == data.item.user_task_id && editable">
                    <b-input-group class="timesheetFlatPicker">
                      <flat-pickr v-model="taskDate" :config="configDate" class="form-control flatpicker-input-show"
                        name="assignment_start_date" placeholder="Select date" />
                    </b-input-group>
                  </b-form-group>
                  <div v-else class="d-flex align-items-center">
                    <p class="date">
                      {{ data.item.date | DateDDMMYYYYFormat }}
                    </p>
                  </div>
                </template>
                <template #cell(desc)="data">
                  <b-form-group v-if="showTaskId == data.item.user_task_id && editable">
                    <!-- #default="{ errors }" -->
                    <validation-provider name="Task Description" rules="required">
                      <b-form-input v-model="taskTitle" class="change-task w-100" name="task_name" type="text"
                        placeholder="Enter Task Description" />
                    </validation-provider>
                  </b-form-group>
                  <div v-else class="align-items-center d-inline-block">
                    <p class="timesheetReportDesc" style="word-break: keep-all">
                      <v-clamp :key="data.item.user_task_id" autoresize :max-lines="5" ellipsis="..." location="end"
                        class="py-1" :style="{ whiteSpace: 'pre-line' }">
                        {{ data.item.user_task_title }}
                        <template #after="{ toggle, clamped, expanded }">
                          <button v-if="clamped === true || expanded === true" class="see-more-btn" @click="toggle">
                            {{ clamped === true ? "see more" : "see less" }}
                          </button>
                        </template>
                      </v-clamp>
                    </p>
                  </div>
                </template>

                <template #cell(tag)="data">
                  <div>
                    <div v-for="(tag, index) in tagsArray(data.item.tags)" :key="index">
                      {{ tag
                      }}{{
                        tagsArray(data.item.tags).length - 1 === index
                          ? null
                          : " ,"
                      }}
                    </div>
                  </div>
                </template>
                <template #cell(start_time)="data">
                  <div v-if="showTaskId == data.item.user_task_id && editable">
                    <!-- #default="{ errors }" -->
                    <validation-provider name="Start time" rules="required">
                      <div class="mb-0 mr-1">
                        <b-form-input v-model="startTime" class="select-item select-time" name="start_time" type="time"
                          placeholder="03:29 PM" />
                      </div>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <div v-if="data.item.start_time" class="align-items-center">
                      {{ data.item.start_time | formatTimeHHMM }}

                    </div>
                  </div>
                </template>
                <template #cell(end_time)="data">
                  <div v-if="showTaskId == data.item.user_task_id && editable">
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Start time"
                      rules="required"
                    > -->
                    <div class="mb-0 mr-1">
                      <b-form-input v-model="endTime" class="select-item select-time" name="start_time" type="time"
                        placeholder="03:29 PM" />
                    </div>
                    <!-- </validation-provider> -->
                  </div>
                  <div v-else>
                    <div v-if="data.item.end_time" class="align-items-center">
                      {{ data.item.end_time | formatTimeHHMM }}
                    </div>
                  </div>
                </template>

                <template #cell(is_billable)="data">
                  <div class="align-items-center">
                    <div :class="[
                      data.item.is_billable ? 'billable' : 'nonBillable',
                    ]" @click="data.item.is_billable = !data.item.is_billable">
                      <span>$</span>
                    </div>
                  </div>
                </template>

                <template #cell(Duration)="data">
                  <div class="align-items-center">
                    {{ data.item.count_minutes | formatTimeHHMM }}
                  </div>
                </template>
                <template #cell(Action)="data">
                  <span v-if="!showTaskId || showTaskId !== data.item.user_task_id" class="d-flex">
                    <feather-icon v-b-tooltip.hover.top="'Edit'" icon="EditIcon" size="21"
                      class="mr-2 edit-icon cursor-pointer" @click="editTaskDtl(data.item)" />
                    <feather-icon v-b-tooltip.hover.top="'Delete'" icon="Trash2Icon"
                      class="text-danger mt-auto cursor-pointer" size="21" @click="deleteTaskDtl(data.item)" />
                  </span>
                  <span v-else class="d-flex">
                    <feather-icon v-b-tooltip.hover.top="'Update'" icon="CheckIcon" size="21"
                      class="mr-2 text-dark cursor-pointer" @click="updateTaskValidation(data.item)" />
                    <feather-icon v-b-tooltip.hover.top="'Cancel'" icon="XIcon" class="trashicon cursor-pointer"
                      size="21" @click="revertChanges(data.item)" />
                  </span>
                </template>
              </b-table>
            </validation-observer>
            <div class="row mt-2 m-2 align-items-center table-pagination" />
          </div>
        </div>
        <div v-else class="w-100">
          <img src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img">
        </div>
      </div>
    </div>
    <vue-html2pdf ref="timesheetreportpdf" :show-layout="false" :float-layout="true" :enable-download="true"
      :preview-modal="false" :pdf-quality="2" :manual-pagination="true" :html-to-pdf-options="pdfOptions"
      @progress="onProgressReport($event)">
      <section slot="pdf-content">
        <section class="pdf-item">
          <ExportTimesheetReport :tasks="groupedReports" :start-date="startDate" :end-date="endDate" :is-name="isName"
            :current-date="currentDate" :total-time="totalDailyTime" />
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import * as moment from 'moment/moment'
import ListLoader from '@/components/loaders/table-loading.vue'
import VClamp from 'vue-clamp'
import VueHtml2pdf from 'vue-html2pdf'
import json2csv from 'json2csv'
import ExportTimesheetReport from './ExportTimesheetReport.vue'

export default {
  name: 'HeaderBlock',
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    ListLoader,
    VClamp,
    ExportTimesheetReport,
    VueHtml2pdf,
  },

  data() {
    return {
      loader: false,
      editable: false,
      exportLoader: false,
      currentPage: 1,
      perPage: 100,
      max: 100,
      dateRange: false,
      selected: { title: 'Square' },
      option: [{ title: 'Square' }, { title: 'Square' }],
      configDate: {
        minDate: moment(this.$route.query.startDate, 'YYYY/MM/DD').format('YYYY/MM/DD'),
        maxDate: moment(this.$route.query.endDate, 'YYYY/MM/DD').format('YYYY/MM/DD'),
        dateFormat: 'Y/m/d',
      },
      multiDate: null,
      hideHeader: true,
      hideFooter: true,
      show: false,
      daily: true,
      weekly: false,
      monthly: false,
      dateRangeConfig: {
        mode: 'range',
        inline: true,
      },

      user_id: null,
      project_id: [],
      projects: [],
      users: [],
      rangeDate: `${moment()
        .startOf('week')
        .add(1, 'd')
        .format('YYYY-MM-DD')} to ${moment()
          .endOf('week')
          .add(1, 'd')
          .format('YYYY-MM-DD')}`,
      startDate: moment().startOf('week').add(1, 'd').format('YYYY-MM-DD'),
      endDate: moment().endOf('week').add(1, 'd').format('YYYY-MM-DD'),
      rangeType: 'day',
      reports: [],
      allTags: [],
      searchUser: [],
      searchProjects: [],
      searchTags: [],
      billableData: null,
      page: 1,
      datashow: true,
      billable_list: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      searchBillable: null,
      groupId: null,
      client_id: [],
      taskDate: null,
      startTime: null,
      endTime: null,
      fields: [
        {
          key: 'name',
          label: 'Name',
          thStyle: { width: '200px' },
          tdStyle: { with: '200px' },
        },
        {
          key: 'project',
          label: 'Project',

          thStyle: { width: '200px' },
          tdStyle: { with: '200px' },
          tdClass: 'custom-column',
        },

        {
          key: 'desc',
          label: 'description',
          thStyle: { minWidth: '350px' },
          tdStyle: { with: '350px' },
          tdClass: 'reportdesc',
        },
        {
          key: 'tag',
          label: 'Tags',
          thStyle: { width: '100px' },
          tdStyle: { with: '100px' },
          tdClass: 'date ',
        },

        {
          key: 'date',
          label: 'Date',
          thStyle: { width: '100px' },
          tdStyle: { with: '100px' },
          tdClass: '',
        },
        {
          key: 'start_time',
          label: 'Start Time',
          thStyle: { width: '80px' },
          tdStyle: { with: '80px' },
          tdClass: 'date',
        },
        {
          key: 'end_time',
          label: 'End Time',
          thStyle: { width: '80px' },
          tdStyle: { with: '80px' },
          tdClass: 'date',
        },
        {
          key: 'is_billable',
          label: 'Billable',
          thStyle: { maxWidth: '80px' },
          tdStyle: { with: '80px' },
          tdClass: 'date',
        },
        {
          key: 'Duration',
          label: 'Duration',
          tdClass: 'duration',
          // sortable: false,
          thStyle: { maxWidth: '80px' },
        },
        {
          key: 'Action',
          label: 'Action',
          tdClass: 'duration',
          // sortable: false,
          thStyle: { width: '80px' },
        },
      ],
      showTaskId: null,
      pageIndex: 0,
      taskProjectId: null,
      isName: false,
      currentDate: moment().format('YYYY-MM-DD'),
      pdfOptions: {
        margin: [20, 0, 20, 0], // [top, right, bottom, left]
        filename: 'TimeSheetReport.pdf',
        image: { type: 'png', quality: 0.75 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css'] },
        // pagebreak: {avoid: ['.main-body .test-new']},
      },
      oldRecord: {},
      groupedReportsPDF: [],
      isExportBillableData: null,
      project_users: [],
      project_work_type: null,
    }
  },
  computed: {
    groupedReports() {
      let reportData = [...this.reports]
      if (this.isExportBillableData) {
        reportData = reportData.filter(item => item.is_billable == 1)
      }
      // Group the reports by project
      const groupedData = reportData.reduce((result, report) => {
        const project = report.project_name
        if (!result[project]) {
          result[project] = []
        }
        result[project].push(report)
        return result
      }, {})
      // Convert the grouped data to an array
      const groupedArray = Object.entries(groupedData).map(
        ([project, reports]) => ({
          project,
          reports,
        }),
      )
      return groupedArray
    },

    reportsList() {
      return this.reports
    },

    totalDailyTime() {
      let reportData = [...this.reports]
      if (this.isExportBillableData) {
        reportData = reportData.filter(item => item.is_billable == 1)
      }
      const totalMinutes = reportData
        .filter(element => element.end_time && element.date)
        .map(element => {
          const startTime = moment(`${element.date} ${element.start_time}`)
          const endTime = moment(`${element.date} ${element.end_time}`)
          return endTime.diff(startTime, 'minutes')
        })
        .reduce((acc, curr) => acc + curr, 0)

      return totalMinutes
        ? this.secondsToTime(totalMinutes * 60)
        : '00:00'
    },
    filteredFields() {
      if (this.userInfo.role === 'EMP' && !this.userInfo.is_resource_managing) {
        // Filter out the "is_billable" field if the user role is employee
        return this.fields.filter(field => field.key !== 'is_billable')
      }
      return this.fields
    },
    filterUserOptions() {
      return this.project_work_type === 'non_productive' ? this.project_users : this.sortedUser(this.project_users)
    },

  },
  watch: {
    taskProjectId(nv) {
      if (nv) this.projectUsers()
    },
  },
  mounted() {
    this.setFilterValue()
    this.timeSheetReport()
  },
  methods: {
    /* Reporting And Manage By Users List */
    async projectUsers() {
      if (this.project_work_type === 'non_productive') {
        if (this.$store.state.app.usersList && this.$store.state.app.usersList.length === 0) {
          this.project_users = await this.userList()
          return
        }
        this.project_users = this.$store.state.app.usersList
        return
      }
      const input = {
        project_id: [this.taskProjectId],
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'timesheet-user-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.project_users = data.user
        this.project_users.push(this.userInfo)
      }
    },

    updateUserInfo(data) {
      const index = this.reports.findIndex(
        bill => bill.user_task_id === data.user_task_id,
      )

      const userIndex = this.project_users.findIndex(
        bill => bill.id === this.user_id,
      )

      if (index >= 0 && userIndex >= 0) {
        this.reports[index].first_name = this.project_users[userIndex].name
        this.reports[index].user_id = this.user_id
        this.reports[index].full_name = this.project_users[userIndex].full_name
        this.reports[index].last_name = this.project_users[userIndex].last_name
        this.reports[index].picture = this.project_users[userIndex].picture ? this.project_users[userIndex].picture : null
      }
    },
    updateTaskValidation(data) {
      if (
        Date.parse(`01/01/2022 ${this.endTime}`)
        <= Date.parse(`01/01/2022 ${this.startTime}`)
      ) {
        this.$bvToast.toast('Please select right time.', {
          toaster: 'b-toaster-top-right',
          solid: true,
          title: 'Unsuccess',
          variant: 'danger',
        })
        return
      }
      if (
        this.taskTitle
        && this.startTime
        && this.taskDate
      ) {
        this.updateTask(data)
      }
    },

    async updateTask(data) {
      const index = this.reports.findIndex(
        bill => bill.user_task_id === data.user_task_id,
      )
      if (index >= 0) {
        this.reports[index].date = moment(this.taskDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        this.reports[index].start_time = this.startTime
        this.reports[index].end_time = this.endTime && this.endTime != 'Invalid date' ? this.endTime : null
        this.reports[index].user_task_title = this.taskTitle
        const timeDiff = this.endTime && this.endTime != 'Invalid date' ? this.countDuration(this.taskDate, this.startTime, this.endTime) : 0
        this.reports[index].count_minutes = this.endTime && this.endTime != 'Invalid date' ? this.secondsToTime(timeDiff * 60) : null

        this.$bvToast.toast('User Task has been updated successfully.', {
          toaster: 'b-toaster-top-right',
          solid: true,
          title: 'Success',
          variant: 'success',
        })
      }
      this.clearEditTask()
    },

    editTaskDtl(task) {
      this.oldRecord = task
      this.user_id = task.user_id
      this.editable = true
      this.taskTitle = task.user_task_title
      this.showTaskId = task.user_task_id
      this.taskProjectId = task.user_task_project_id
      this.taskDate = moment(task.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.project_work_type = task.project_work_type
      console.log('task.project_work_type', task)
      // Helper function to format time
      const formatTime = (date, time) => moment(`${date} ${time}`).format('HH:mm:ss')

      // Set start and end time
      this.startTime = formatTime(task.date, task.start_time)

      this.endTime = formatTime(task.date, task.end_time)

      // Set task tags
      if (task.tags) {
        this.taskTags = this.tagsArray(task.tags)
      }
    },

    clearEditTask() {
      this.showTaskId = null
    },

    revertChanges(data) {
      const index = this.reports.findIndex(
        bill => bill.user_task_id === data.user_task_id,
      )
      this.user_id = this.oldRecord.user_id
      this.updateUserInfo(data)
      this.reports[index] = this.oldRecord

      this.clearEditTask()
    },

    /**
     * Get report data
     */
    async timeSheetReport() {
      this.loader = true
      this.reports = []
      const input = {
        start_date: this.startDate,
        end_date: this.endDate,
        member: this.searchUser,
        project: this.searchProjects,
        tags: this.searchTags,
        billable: this.searchBillable ? this.searchBillable === 'yes' : null,
        client_id: this.client_id,
        group_id: this.groupId,
        export: true,
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/reports/time-sheet-report-sql',
        input,
        false,
      )
      if (response && response.status === 200) {
        const { data } = response

        const keys = Object.keys(data.user_tasks)
        const tasks = []
        keys.forEach(element => {
          data.user_tasks[element].forEach(e => {
            tasks.push(e)
          })
        })

        this.reports = tasks
        setTimeout(() => {
          this.$refs.timesheetScrollRef.scrollTop = this.$route.query.pageIndex
        }, 800)
        this.loader = false
      } else {
        this.loader = false
      }
    },

    setFilterValue() {
      if (!this.$route.query) return

      const { query } = this.$route

      // Set search, perPage, and currentPage
      this.search = query.search
      this.perPage = query.perPage || 100
      this.currentPage = query.currentPage || 1

      // Set startDate, endDate, rangeDate, searchUser, searchProjects, searchTags, and searchBillable
      this.startDate = query.startDate || query.summaryStartDate || this.startDate
      this.endDate = query.endDate || query.summaryEndDate || this.endDate
      this.rangeDate = `${moment(this.startDate).format(
        'YYYY-MM-DD',
      )} to ${moment(this.endDate).format('YYYY-MM-DD')}`
      this.searchUser = query.member
        ? typeof query.member === 'string'
          ? [query.member]
          : query.member
        : this.searchUser
      this.searchProjects = query.project
        ? typeof query.project === 'string'
          ? [query.project]
          : query.project
        : this.searchProjects
      this.searchTags = query.tag
        ? typeof query.tag === 'string'
          ? this.tagsArray(query.tag.trim())
          : query.tag
        : this.searchTags
      this.searchBillable = query.isBillable || query.summaryIsBillable || this.searchBillable

      // Call getGroupId if group is provided
      if (query.group) {
        this.getGroupId(query.group)
      }

      // Set client_id
      this.client_id = query.client
        ? typeof query.client === 'string'
          ? [query.client]
          : query.client
        : this.client_id
      this.pageIndex = query.pageIndex || 0

      // Call timeSheetReport
      this.timeSheetReport()
    },

    async getGroupId(value) {
      if (this.$store.state.app.groupList.length === 0) {
        await this.getGroupData()
      }
      const group = this.$store.state.app.groupList.find(
        option => option.label === value,
      )

      this.groupId = group && group.value ? group.value : this.groupId
    },

    confirmationFunction(type) {
      this.$swal({
        title: 'Note',
        // icon: "info",
        html:
          this.userInfo.role === 'SA' || this.userInfo.is_resource_managing
            ? "<p class='my-1'>Are you sure want to export?</p>  <div class='d-flex justify-content-center'> <div class=' d-flex justify-content-center align-items-center'>  <input type=checkbox id=vehicle1 name=vehicle1  class=input-aleart></input> <label for=vehicle1 class='label-sweet mb-0'> Include name</label></div>  <div class='d-flex justify-content-center align-items-center'><input type=checkbox id=vehicle2 name=vehicle2 checked class='ml-1 input-aleart'></input> <label for=vehicle2 class='label-sweet mb-0'> Only Billable </label></div></div>"
            : "<p class='my-1'>Are you sure want to export?</p>  <div class='d-flex justify-content-center'> <div class=' d-flex justify-content-center align-items-center'>  <input type=checkbox id=vehicle1 name=vehicle1  class=input-aleart></input> <label for=vehicle1 class='label-sweet mb-0'> Include name</label></div>  <div class='d-flex justify-content-center align-items-center'><input type=checkbox id=vehicle2 name=vehicle2  class='ml-1 input-aleart'></input> <label for=vehicle2 class='label-sweet mb-0'> Only Billable </label></div></div>",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const id = document.getElementById('vehicle1').checked
          const billable = document.getElementById('vehicle2').checked

          this.generateReport(id, type, billable)
        }
      })
    },
    /** Start Export Data logic */
    async generateReport(id, type, billable) {
      this.isExportBillableData = billable

      this.isName = id
      if (type === 'CSV') {
        let reportData = this.reportsList

        if (billable) {
          // Filter data based on is_billable
          reportData = reportData.filter(item => item.is_billable == 1)
        }

        const jsonData = this.transformData(reportData)
        const nameHeader = { name: 'Name' }
        const generalHeader = {
          project: 'Project',
          title: 'Title',
          date: 'Date',
          startTime: 'Start Time',
          endTime: 'End Time',
          countMinutes: 'Count Minutes',
        }

        let header = {}
        if (id) {
          header = {
            ...nameHeader,
            ...generalHeader,
          }
        } else {
          header = generalHeader
        }

        // Add header row
        jsonData.unshift(header)

        const headers = Object.keys(jsonData[0])
        const columnLengths = this.calculateColumnLengths(jsonData, headers)

        const csv = json2csv.parse(jsonData, {
          fields: headers,
          header: false,
        })
        const adjustedCsv = this.adjustColumnWidths(
          csv,
          headers,
          columnLengths,
        )

        this.downloadCsvFile(adjustedCsv)
      } else {
        this.isExporting = true
        this.$refs.timesheetreportpdf.generatePdf()
      }
    },

    onProgressReport(e) {
      if (e == 100) {
        this.exportLoader = false
        this.isExportBillableData = null
      } else {
        this.exportLoader = true
      }
    },

    transformData(data) {
      return data.map(item => ({
        name: item.full_name,
        project: item.project_name,
        title: item.user_task_title,
        date: item.date,
        startTime: item.start_time,
        endTime: item.end_time,
        countMinutes: item.count_minutes,
      }))
    },

    calculateColumnLengths(data, headers) {
      return headers.reduce((acc, header) => {
        acc[header] = Math.max(
          10,
          ...data.map(item => String(item[header]).length),
        )
        return acc
      }, {})
    },

    adjustColumnWidths(csv, headers, columnLengths) {
      const lines = csv.split('\n')
      return lines
        .map(line => line
          .split(',')
          .map((cell, index) => cell.padEnd(columnLengths[headers[index]]))
          .join(','))
        .join('\n')
    },

    downloadCsvFile(csvData) {
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'TimeSheetReport.csv'

      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },

    /** End Export Data logic */
    back() {
      this.$emit('closeEditDataPage')
    },
    countDuration(date, startTime, endTime) {
      const start_time = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm')
      const end_time = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm')
      const durationMinutes = end_time.diff(start_time, 'minutes')
      return durationMinutes || 0
    },

    /** Delete task */
    async deleteTaskDtl(data) {
      const value = await this.conformationAlert(true)
      if (value.isConfirmed === true) {
        this.reports = this.reports.filter(
          obj => obj.user_task_id !== data.user_task_id,
        )
        this.$bvToast.toast('User Task has been deleted successfully.', {
          toaster: 'b-toaster-top-right',
          solid: true,
          title: 'Success',
          variant: 'success',
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/approvals.css";
@import "../../assets/scss/component-css/report.css";

.total-hr-timespent-edit {
  color: #5e5873;
  font-weight: 600;
  font-size: 2.25 * 8px;
  text-align: end;
  margin-right: 15px;
  padding: 5px 0px;

  span {
    color: #ff3700;
  }
}

.cursor-default {
  cursor: default !important;
}

.flatpicker-input-show {
  display: block !important;
}

.viewEdit .timesheetFlatPicker .flatpickr-input {
  display: block !important;
}
</style>
